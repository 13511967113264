<template>
  <div class="partners">
    <!-- 1 -->
    <div class="title">Партнерам</div>
    <!-- 2 -->
    <div class="bgimg"></div>
    <!-- 3 -->
    <div class="sec1">
      <div class="title" v-html="sec1title"></div>
      <img class="cowr" src="../assets/partners/cowr.png" alt="" />
      <img class="milkl" src="../assets/partners/milkl.png" alt="" />
      <img class="milklbg" src="../assets/partners/milklbg.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sec1title: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await fetch("https://cd20321.tmweb.ru/api/articles/");
      const finalRes = await res.json();
      this.sec1title = finalRes[57].text.replaceAll(";", "<br />");
    },
  },
};
</script>
<style lang="scss">
@media (min-width: 416px) {
  .partners {
    .title {
      font-weight: 600;
      font-size: 100px;
      line-height: 144px;
      text-align: center;
    }
    .bgimg {
      background-image: url("../assets/partners/bg.png");
      background-size: cover;
      height: 538px;
    }
    .sec1 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: minmax(auto, 500px) 1fr;
      gap: 30px;
      margin-bottom: 90px;
      .title {
        grid-column: 1 / span 2;
        grid-row: 1;
        font-weight: 500;
        font-size: 40px;
        line-height: 58px;
        align-self: flex-end;
        text-align: left;
        padding-left: 231px;
        span {
          display: inline-block;
          position: relative;
        }
        span::before {
          content: "";
          position: absolute;
          bottom: 20%;
          left: 0;
          width: 100%;
          height: 25%;
          background-color: #d9e1cd;
          z-index: -1;
        }
      }
      .cowr {
        width: 605px;
        grid-column: 2;
        grid-row: 2;
      }
      .milkl {
        width: 374px;
        grid-column: 1;
        grid-row: 2;
        justify-self: flex-end;
        align-self: flex-end;
      }
      .milklbg {
        width: 460px;
        grid-column: 1;
        grid-row: 2;
        z-index: -1;
        justify-self: flex-end;
        align-self: flex-end;
      }
    }
  }
}
@media (max-width: 415px) {
  .partners {
    .title {
      font-weight: 600;
      font-size: 50px;
      line-height: 72px;
      text-align: center;
    }
    .bgimg {
      background-image: url("../assets/partners/bgm.png");
      background-size: cover;
      height: 516px;
    }
    .sec1 {
      display: grid;
      grid-template-columns: minmax(auto, 335px);
      grid-template-rows: repeat(3, auto);
      gap: 30px;
      margin-bottom: 90px;
      justify-content: center;
      margin-top: 60px;
      .title {
        grid-column: 1;
        grid-row: 1;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        text-align: left;
        span {
          display: inline-block;
          position: relative;
        }
        span::before {
          content: "";
          position: absolute;
          bottom: 20%;
          left: 0;
          width: 100%;
          height: 25%;
          background-color: #d9e1cd;
          z-index: -1;
        }
      }
      .cowr {
        width: 335px;
        grid-column: 1;
        grid-row: 3;
      }
      .milkl {
        width: 266px;
        grid-column: 1;
        grid-row: 2;
        justify-self: flex-end;
        align-self: flex-end;
      }
      .milklbg {
        width: 335px;
        grid-column: 1;
        grid-row: 2;
        z-index: -1;
        justify-self: flex-end;
        align-self: flex-end;
      }
    }
  }
}
</style>
